import React from 'react';
import { Helmet, HelmetProps } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import Navigation from './nav';
import Footer from './footer';
import Notification from './notification';
import { FixedBackground, IsPurpleAssetImage } from './section';
import createTranslate from '../utils/translator-provider';
import { AssetImage, useSiteMetadata } from '../utils/gatsby-types';
import { DefaultPageTheme, PageTheme, PageThemeContext } from '../utils/context-page-theme';

export interface LayoutProps{
  title: string;
  description?: string;
  path?: string;
  type?: string;
  published?: string;
  publicURL?: string;
  fixedBackground?: AssetImage;
  ogURL?: string;
}

const layout: React.FC<LayoutProps> = ({ title, description, path, type, published, publicURL, fixedBackground, ogURL, children }) => {
  // Generate page theme, override defaults
  const pageTheme: PageTheme = {
    ...DefaultPageTheme,

    isPurple: IsPurpleAssetImage(fixedBackground),
  }

  const siteMetadata = useSiteMetadata();

  const data = useStaticQuery(graphql`
    query {
      page: contentfulPage(name: { eq: "General layout" }) {
        title
        translations {
          key
          value {
            value
          }
        }
      }

      commonMetadata: allContentfulSiteMetadata {
        nodes {
          siteName
          siteBaseUrl
          siteTitlePrefix
          themeColor
          socialMediaImage {
            localFile {
              publicURL
            }
          }
          googleSiteVerification
          openGraphArticleSection
          openGraphType
          twitterCardType
          twitterHandle
        }
      }
    }
  `);

  const translate = createTranslate(data?.page?.translations);

  const commonMetadata =
    data.commonMetadata.nodes.length > 0 ? data.commonMetadata.nodes[0] : {};

  const socialMediaImage = `${commonMetadata.siteBaseUrl}${publicURL || commonMetadata.socialMediaImage?.localFile?.publicURL}`;

  const helmetScripts: HelmetProps["script"] = [];

  if(!ogURL){
    ogURL = `${commonMetadata.siteBaseUrl}${path}`;
  }

  const meta: HelmetProps["meta"] = [
    { name: 'description', content: description },
    { name: 'theme-color', content: commonMetadata.themeColor },
    { name: 'twitter:card', content: commonMetadata.twitterCardType },
    { name: 'twitter:site', content: commonMetadata.twitterHandle },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: description },
    { name: 'twitter:image', content: socialMediaImage },
    { property: 'article:published_time', content: published },
    { prefix: "og: http://ogp.me/ns#", property: 'og:url', content: ogURL },
    { property: 'og:description', content: description },
    { prefix: "og: http://ogp.me/ns#", property: 'og:type', content: `${type ? type : 'website'}` },
    { property: 'og:site_name', content: commonMetadata.siteName },
    { prefix: "og: http://ogp.me/ns#", property: 'og:title', content: title },
    { prefix: "og: http://ogp.me/ns#",
      name: 'image',
      property: 'og:image',
      content: socialMediaImage,
    },
    {
      property: 'lp:image',
      content: socialMediaImage,
    },
  ];

  // Add conditional entries
  if(commonMetadata.googleSiteVerification){
    [
      { name: 'google-site-verification', content: commonMetadata.googleSiteVerification }
    ].forEach(entry => meta.push(entry));
  }

  if(commonMetadata.openGraphArticleSection){
    [
      { property: 'article:section', content: commonMetadata.openGraphArticleSection}
    ].forEach(entry => meta.push(entry))
  }


  // Construct page title
  const pageTitle = title + (commonMetadata.siteTitlePrefix ? ` | ${commonMetadata.siteTitlePrefix}` : "");


  return (
    <>
      <Helmet
        title={pageTitle}
        htmlAttributes={{ lang: 'fi' }}
        meta={meta}
        script={helmetScripts}
      >
        <link rel="canonical" href={`${siteMetadata.siteUrl}${path}`} />.

        <link rel="stylesheet" href="https://use.typekit.net/wdt5eku.css"/>
      </Helmet>

      <FixedBackground background={fixedBackground}/>
      <Navigation />
      <PageThemeContext.Provider value={pageTheme}>
        {children}
      </PageThemeContext.Provider>
      <Footer />
      {typeof window !== 'undefined' &&
        <Notification />
      }
    </>
  );
};
export default layout;
